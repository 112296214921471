import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

// third-party
import jwtDecode from 'jwt-decode';

// reducer - state management
import { REGISTER, LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer, {initialState} from 'store/reducers/auth';

// project-imports
import Loader from 'components/Loader';
import axios from 'utils/axios';

const verifyToken = (refreshToken) => {
  if (!refreshToken) return false;
  try {
    const decoded = jwtDecode(refreshToken);
    return decoded.exp > Date.now() / 1000;
  } catch (error) {
    console.error('Error decoding refresh token:', error);
    return false;
  }
};

const setSession = (id, serviceToken, refreshToken, userData, role) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('userData', JSON.stringify(userData));
    localStorage.setItem('role', role);
    localStorage.setItem(role === 'vendor' ? 'vendorId' : 'influencerId', id);
    axios.defaults.headers.common.Authorization = serviceToken;
    axios.defaults.headers.common.refreshToken = refreshToken;
  } else {
    localStorage.removeItem('serviceToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userData');
    localStorage.removeItem('role');
    localStorage.removeItem('vendorId');
    localStorage.removeItem('influencerId');
    delete axios.defaults.headers.common.Authorization;
    delete axios.defaults.headers.common.refreshToken;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const navigate = useNavigate(); 

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = localStorage.getItem('serviceToken');
        const refreshToken = localStorage.getItem('refreshToken');

        if (refreshToken && verifyToken(refreshToken)) {
          const userData = JSON.parse(localStorage.getItem('userData'));
          const role = localStorage.getItem('role');
          const id = role === 'vendor' ? localStorage.getItem('vendorId') : localStorage.getItem('influencerId');

          setSession(id, serviceToken, refreshToken, userData, role);
          dispatch({ type: LOGIN, payload: { user: userData } });
        } else {
          await logout();
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT
        });
      }
    };

    init();
  }, []);

  const login = async (role, mobile, password) => {
    const endpoint = role === 'vendor' ? '/vendor/login' : '/influencer/login';
    const response = await axios.post(endpoint, { mobile, password });

    let userData = response.data.result.data;
    userData = { ...userData, role };

    setSession(userData.id, userData.token, userData.refreshToken, userData, role);
    
    dispatch({ type: LOGIN, payload: { user: userData } });

    return role;
  };

  const register = async (role, userDetails) => {
    const endpoint = role === 'vendor' ? '/vendor/register' : '/influencer/register';
    await axios.post(endpoint, userDetails);
    dispatch({ type: REGISTER });
  };

  const logout = async () => {
    const role = localStorage.getItem('role');
    if (!role) return;

    const endpoint = role === 'vendor' ? '/vendor/logout' : '/influencer/logout';
    try {
      await axios.post(endpoint, { id: state.user?.id || role === 'vendor' ? localStorage.getItem("vendorId") : localStorage.getItem("influencerId")});
    } catch (error) {
      console.error('Logout API error:', error);
    } finally {
      setSession(null, null, null, null, null);
      dispatch({ type: LOGOUT });
      if (role === 'influencer') {
        navigate('/influencer/login');
      } else {
        navigate('/login');
      }
    }
  };

  const resetPassword = async () => { };

  const updateProfile = () => { };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>;
};

JWTProvider.propTypes = {
  children: PropTypes.node
};

export default JWTContext;
